import React from "react"
import Layout from "../components/layout.js"
import { Link } from "gatsby"
import Seo from "../components/seo.js"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
// import PartnerSwiper from "../components/swiper/swiper-partner.js"
import { RiAlarmWarningLine } from "react-icons/ri"
import { RiFileSettingsLine } from "react-icons/ri"
import { RiLightbulbLine } from "react-icons/ri"
import { RiTeamFill } from "react-icons/ri"
// import Cases from "../components/cases"
import Banner from "../components/hero/hero-finance-industry.js"
// import Testimonials from "../components/swiper/testimonial-swiper"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const Modal = loadable(() =>
  import("../components/modal/modal-finance-industry.js")
)

const UnlockEnterprise = loadable(() =>
  pMinDelay(import("../components/unlock-enterprise.js"), 500)
)

const Testimonials2 = loadable(() =>
  pMinDelay(import("../components/swiper/testimonial-swiper.js"), 500)
)

const Cases = loadable(() => pMinDelay(import("../components/cases.js"), 500))

class FinanceIndustryPage extends React.Component {
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="Financial Services | Quandary Consulting Group"
          description="We automate data management for financial institutions so they can exceed customer expectations, navigate markets, reduce risk, and stay compliant."
        />

        <LazyLoadComponent>
          <section
            className="quickbase-integrations"
            style={{ padding: "5rem 0" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <h2 className="h3 text-white mt-4">
                    END-TO-END AUTOMATION FOR FINANCIAL SERVICE WORKFLOWS AND
                    PROCESSES
                  </h2>
                  <p className="text-white">
                    Satisfy Customers. Create Value. Reduce Risk. Streamline
                    operations. Stay Compliant. Increase Your Bottom Line.
                  </p>
                </div>

                <div className="col-lg-4 col-md-6 mt-5 text-center">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="../images/workflow-optimize.png"
                    alt="Workflow Optimization"
                    layout="constrained"
                    style={{ width: "25%" }}
                    loading="lazy"
                  />
                  <p className="my-4 text-white font-weight-bold text-uppercase">
                    Workflow Optimization
                  </p>
                  <p className="pt-2 text-white">
                    Remove inefficiencies, roadblocks, and redundancies to
                    streamline operations in your financial organization.
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 mt-5 text-center">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="../images/realtime-data.png"
                    alt="Custom ERP Development"
                    layout="constrained"
                    style={{ width: "25%" }}
                    loading="lazy"
                  />
                  <p className="my-4 text-white font-weight-bold text-uppercase">
                    Custom ERP Development
                  </p>
                  <p className="pt-2 text-white">
                    Overhaul existing finance systems to improve operations,
                    team management, data collection, and business outcomes.
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 mt-5 text-center">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="../images/system-maintenance.png"
                    alt="Ongoing System Maintenance"
                    layout="constrained"
                    style={{ width: "25%" }}
                    loading="lazy"
                  />
                  <p className="my-4 text-white font-weight-bold text-uppercase">
                    Ongoing System Maintenance
                  </p>
                  <p className="pt-2 text-white">
                    Embed our team within yours to maintain and improve your
                    existing ERP and business systems as your business adapts to
                    changing markets.
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 mt-5 text-center">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="../images/access-management.png"
                    alt="Access Management"
                    layout="constrained"
                    style={{ width: "25%" }}
                    loading="lazy"
                  />
                  <p className="my-4 text-white font-weight-bold text-uppercase">
                    Access Management
                  </p>
                  <p className="pt-2 text-white">
                    Secure sensitive financial data by controlling who has
                    access to your data, keeping your organization compliant.
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 mt-5 text-center">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="../images/nextgen-oms.png"
                    alt="Workato Logo"
                    layout="constrained"
                    style={{ width: "25%" }}
                    loading="lazy"
                  />
                  <p className="my-4 text-white font-weight-bold text-uppercase">
                    Financial Reporting
                  </p>
                  <p className="pt-2 text-white">
                    Integrate your existing financial tool stack with your ERP
                    and get clear reporting from numerous data streams in
                    real-time.
                  </p>
                </div>
                <div className="col-lg-4 col-md-6 mt-5 text-center">
                  <StaticImage
                    placeholder="blurred"
                    className="img-fluid rounded"
                    src="../images/system-integration.png"
                    alt="System Integration"
                    layout="constrained"
                    style={{ width: "25%" }}
                    loading="lazy"
                  />
                  <p className="my-4 text-white font-weight-bold text-uppercase">
                    System Integration
                  </p>
                  <p className="pt-2 text-white">
                    Connect applications, systems, and workflows with low-code
                    integrations that reduce bottlenecks and menial tasks.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </LazyLoadComponent>

        <LazyLoadComponent>
          <section className="what-to-expect" style={{ padding: "5rem 0" }}>
            <div className="container pb-5">
              <div className="row">
                <div className="col-lg-12 col-md-8 mx-auto text-center">
                  <h2 className="font-weight-medium">
                    WE KNOW FINANCIAL SERVICES.
                  </h2>
                  <p className="mt-3">
                    We help your organization navigate changing markets, improve
                    data visibility, automate spreadsheet work, and deliver
                    unmatched customer service.
                  </p>
                </div>
              </div>
              <div className="row" style={{ padding: "65px 0px 0px 0px" }}>
                <div
                  className="col mb-4 mx-5 what-to-expext-box text-center"
                  style={{ borderRadius: "5px" }}
                >
                  <p className="text-green pt-3 font-weight-bold">DISCOVERY</p>
                  <p className="my-3 pb-3">
                    We understand your business first. Digging deep to uncover
                    the source of the problem.
                  </p>
                </div>
                <div
                  className="col mb-4 mx-5 what-to-expext-box text-center"
                  style={{ borderRadius: "5px" }}
                >
                  <p className="text-green pt-3 font-weight-bold">
                    DEVELOPMENT
                  </p>
                  <p className="my-3 pb-3">
                    We build high-quality, custom applications quickly using
                    low-code tools in weeks (not 12-18 months).
                  </p>
                </div>
                <div
                  className="col mb-4 mx-5 what-to-expext-box text-center"
                  style={{ borderRadius: "5px" }}
                >
                  <p className="text-green pt-3 font-weight-bold">DEPLOYMENT</p>
                  <p className="my-3 pb-3">
                    We launch the new tools and support your team while sharing
                    opportunities we uncovered for future growth.
                  </p>
                </div>
              </div>
              <div className="col-lg-8 mx-auto text-center pt-5">
                <Modal />
              </div>
            </div>
          </section>
        </LazyLoadComponent>

        <LazyLoadComponent>
          <section id="case-studies-container">
            <div id="case-studies" className="bg-indigo">
              <div className="container-fluid">
                <div className="intro text-center">
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <p
                        className="h5 text-light-blue text-uppercase font-weight-bold m-0"
                        style={{ fontFamily: "Futura,sans-serif" }}
                      >
                        Case Studies
                      </p>
                      <h2 className=" my-2 text-white">
                        We Save Clients 1000s of Hours. Every Year.
                      </h2>
                      {/* <p className="text-white">
                          We helped a national healthcare provider save over
                          9,000 hours in weekly labor costs by developing a
                          single application...
                        </p> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <Suspense fallback={<div>Loading...</div>}> */}
              <div className="">
                <Cases
                  allSanityCaseStudy={this.props.data.allSanityCaseStudy}
                />
              </div>
              {/* </Suspense> */}
              <div className="text-center">
                <Link
                  className="btn btn-primary btn-arrow mr-2 mt-4 text-center"
                  to="/case-studies/"
                >
                  More Case Studies
                </Link>
              </div>
            </div>
          </section>
        </LazyLoadComponent>

        <LazyLoadComponent>
          <section id="case-studies-container">
            <div id="case-studies">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center">
                    <h2>
                      End-to-End Automation for Complex Financial Processes
                    </h2>
                    <p>
                      Eliminate tedious operational workflows and processes,
                      giving your team more time to succeed. Without burning
                      through your IT budget.
                    </p>

                    <div className="relative">
                      <Modal />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </LazyLoadComponent>

        <UnlockEnterprise />

        <Testimonials2 />
      </Layout>
    )
  }
}

export default FinanceIndustryPage

export const financeIndustryPageQuery = graphql`
  query financeIndustryPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
